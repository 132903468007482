export function buildEventDateTimeString(
  eventDate: string,
  startTime: string
): string {
  return `${eventDate} | ${
    startTime.length < "10:00 a.m. - 11:00 p.m.".length
      ? startTime
      : "Multiple Times Available"
  }`;
}
