import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize, quicksandFont } from "../../utils/css/themes";
import { GatsbyImage } from "gatsby-plugin-image";
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import ArrowButtonContainer from "../Helpers/ArrowButtonContainer";
import DangerousHtml from "../Helpers/DangerousHtml";
import { CalendarEvent } from "../../pages/events";

const EventTiles = (props: EventData): JSX.Element => {
  const TileContainer = styled.a`
    display: flex;
    @media (min-width: ${screenSize.largeTablet}) {
      flex-direction: row;
      justify-content: space-between;
      max-height: 218px;
      margin: 0 auto 3rem;
    }
    flex-direction: column;
    border-radius: 5px;

    background-color: white;
    width: 100%;
    margin-bottom: 3rem;
    box-shadow: 4px 10px 20px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;

    &:hover {
      span {
        border: solid 1px ${colors.blue};
        color: white;
        background-color: ${colors.blue};
      }
    }
  `;

  const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0.5rem;
    @media (min-width: ${screenSize.largeTablet}) {
      width: 100%;
    }

    margin-left: 2rem;
    margin-right: 2rem;
    justify-content: space-around;
    background-color: white;
  `;
  const LeftContainer = styled.div`
    @media (min-width: ${screenSize.largeTablet}) {
      max-width: 262px;
    }

    max-height: 218px;
    width: 100%;
  `;

  const DateTitle = styled.p`
    margin-top: 0.5rem;
    font-family: ${quicksandFont.bold};
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: 1.2px;
    color: ${colors.blue};
  `;

  const Title = styled.div`
    font-size: 24px;
    line-height: 1.08;
    color: ${colors.blue};
    div {
      font-family: Baloo;
    }
  `;

  const Description = styled.p`
    font-family: ${quicksandFont.regular};
    font-size: 16px;
    font-weight: 500;
    line-height: 1.29;
    color: ${colors.darkGrey};
    p {
      margin: 0.3rem 0;
    }
    ul {
      margin: 0;
    }
  `;

  const ImgCSS = css`
    display: list-item !important;
    border-radius: 3px;
    @media (min-width: ${screenSize.largeTablet}) {
      width: 100%;
      min-width: 262px;
      border-radius: 4% 12% 12% 4% / 3% 100% 100% 3%;
    }

    min-height: 218px;
    max-height: 218px;
  `;
  const EventURL = "/events/" + props.event.slug;
  function displayTile() {
    const fullDate = props.event.eventsType.eventDate;
    var indexOfMonth = fullDate.indexOf(" ");
    var monthToDisplay = fullDate.substring(0, indexOfMonth);
    var yearToDisplay = fullDate.substring(fullDate.length - 4);

    if (
      monthToDisplay != props.Months[props.CurrentMonth] ||
      parseInt(yearToDisplay) < new Date().getFullYear()
    ) {
      return null;
    }

    return (
      <TileContainer href={EventURL} key={props.key}>
        <LeftContainer>
          {props.event.eventsType?.eventImage?.localFile && (
            <GatsbyImage
              css={ImgCSS}
              alt={"accordion image"}
              image={
                props.event.eventsType?.eventImage?.localFile.childImageSharp
                  ?.gatsbyImageData
              }
            />
          )}
        </LeftContainer>
        <RightContainer>
          <div
            css={css`
              padding-top: 1rem;
            `}
          >
            <DateTitle>
              {props.event.eventsType.eventDate} |{" "}
              {props.event.eventsType.startTime}
            </DateTitle>
            <Title>
              <DangerousHtml>{props.event.title}</DangerousHtml>
            </Title>
          </div>
          <Description>{props.event.eventsType.eventDescription}</Description>
          <ArrowButtonContainer>&rarr;</ArrowButtonContainer>
        </RightContainer>
      </TileContainer>
    );
  }

  return <>{displayTile()}</>;
};

interface EventData {
  Months: Array<String>;
  CurrentMonth: number;
  event: CalendarEvent;
  key: number;
}
export default EventTiles;
