import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import EventTiles from "../EventsPage/EventTiles";
import EventsPageAccordion from "../EventsPage/EventsPageMonthAccordion";
import ViewToggleButton from "./ViewToggleButton";
import { CalendarEvent } from "../../pages/events";

const EventsByMonth = (props: any) => {
  let {
    eventPosts,
    toggleViewSelection,
  }: { eventPosts: CalendarEvent[]; toggleViewSelection: VoidFunction } = props;
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [toggleOpen, setToggleOpen] = useState(null);
  function changeMonth(month: number) {
    setCurrentMonth(month);
    setToggleOpen(null);
  }

  // This is used for calculating which events to display
  const Months = new Array(
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  );

  const TopNavigation = styled.div`
    display: flex;
    justify-content: center;
    @media (min-width: ${screenSize.smallTablet}) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
    }
    margin: 1rem auto 0;
    text-align: center;
    flex-direction: column;
  `;

  const Tiles = styled.div`
    margin-top: 2rem;
    margin: 2rem auto 0;
  `;

  const DropDownContainer = styled.div`
    @media (min-width: ${screenSize.smallTablet}) {
      margin: -1rem 0 0 0;
    }
    margin: -1rem auto;
    max-width: 270px;
    width: 100%;
  `;

  const DropDownHeader = styled.div`
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    color: ${colors.darkGrey};
    margin-bottom: 0.5rem;
    @media (min-width: ${screenSize.smallTablet}) {
      margin: 0;
      text-align: left;
    }
  `;

  const BottomNavigation = styled.div`
    display: flex;
    justify-content: space-between;
    @media (min-width: ${screenSize.smallTablet}) {
      flex-direction: row;
    }
    flex-direction: column;
    margin: 0 auto 19rem;
  `;

  const NavButton = styled.button`
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: ${colors.blue};
    border-radius: 40px;
    border: solid 1px ${colors.orange};
    background-color: white;

    margin: 0;
    min-width: 107px;
    max-height: 26px;
    padding: 0.3rem 0.6rem;
    &:hover {
      color: white;
      border: solid 1px ${colors.blue};
      background-color: ${colors.blue};
      cursor: pointer;
      span {
        color: white;
      }
    }
      @media (max-width: ${screenSize.smallTablet}) {
        padding: 1rem 0.5rem;
        margin: 0 auto 2rem;
        max-width: 207px;
        max-height: 100px;
        width: 100%;
      }
     
    }
  `;

  function CreateDropdown() {
    return (
      <DropDownContainer>
        <DropDownHeader>Filter by Month</DropDownHeader>
        <EventsPageAccordion
          Months={Months}
          CurrentMonth={currentMonth}
          setCurrentMonth={setCurrentMonth}
        />
      </DropDownContainer>
    );
  }

  function ContructBottomNavButtons() {
    var prevMonth = currentMonth - 1 == -1 ? 11 : currentMonth - 1;
    var nextMonth = currentMonth + 1 == 12 ? 0 : currentMonth + 1;
    var navButtonArray = new Array(
      (
        <BottomNavigation>
          <NavButton onClick={() => changeMonth(prevMonth)}>
            <span
              css={css`
                color: ${colors.orange};
              `}
            >
              {" "}
              &larr;
            </span>{" "}
            {Months[prevMonth]}
          </NavButton>
          <NavButton onClick={() => changeMonth(nextMonth)}>
            {Months[nextMonth]}{" "}
            <span
              css={css`
                color: ${colors.orange};
              `}
            >
              {" "}
              &rarr;
            </span>{" "}
          </NavButton>
        </BottomNavigation>
      )
    );
    return navButtonArray;
  }

  function GenerateTiles() {
    if (!eventPosts) {
      return (
        <p style={{ textAlign: "center" }}>
          Could not find events for {Months[currentMonth]}
        </p>
      );
    }

    return (
      <Tiles>
        {eventPosts.map((event: any, index: number) => {
          return (
            <EventTiles
              Months={Months}
              CurrentMonth={currentMonth}
              event={event}
              key={index}
            />
          );
        })}
      </Tiles>
    );
  }

  return (
    <div
      css={css`
        padding: 0 1rem;
        @media (min-width: ${screenSize.largeDisplay}) {
          padding: 0;
        }
      `}
    >
      <TopNavigation>
        {CreateDropdown()}
        <ViewToggleButton
          buttonLabel={"View Featured Events"}
          toggleViewSelection={toggleViewSelection}
        />
      </TopNavigation>
      <Tiles>{GenerateTiles()}</Tiles>
      {ContructBottomNavButtons()}
    </div>
  );
};

export default EventsByMonth;
