import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";

/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import Caret from "../../utils/images/faq-carot.inline.svg";
import Accordion from "../../components/Helpers/Accordion";
import { useState } from "react";
import { CalendarEvent } from "../../pages/events";

const EventsPageAccordion = (props: MonthData): JSX.Element => {
  const [toggleOpen, setToggleOpen] = useState(null);
  function changeMonth(month: number) {
    props.setCurrentMonth(month);
    setToggleOpen(null);
  }
  const CaretTitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${colors.blue};

    border-radius: 5px;

    @media (min-width: ${screenSize.smallTablet}) {
      background-color: ${toggleOpen === 0 ? colors.blue : "white"};
      &:hover {
        cursor: pointer;
        background-color: ${toggleOpen === 0 ? colors.blue : colors.blueGrey};
      }
    }
    background-color: ${colors.blue};
  `;

  const AccordionTitle = styled.div`
    padding: 0.5rem 1rem;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    line-height: 1.43;
    letter-spacing: 0.58px;
    text-align: center;
    max-width: 270px;
    text-align: left;
    @media (min-width: ${screenSize.smallTablet}) {
      color: ${toggleOpen === 0 ? "white" : colors.blue};

      font-size: 14px;
      font-weight: bold;
    }
    font-size: 18px;
    font-weight: bold;
    color: white;
  `;

  const ContentStyle = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -1rem;
    min-width: 270px;
    width: 100%;
    z-index: 1;
    background-color: white;
    border-radius: 5px;

    border: 1px solid ${colors.blue};
    border-top: 0;
    @media (min-width: ${screenSize.smallTablet}) {
      padding: 0.5rem 0;
    }
  `;

  const AccordionLink = styled.button`
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI,
      Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;

    letter-spacing: 0.58px;
    text-align: left;
    color: ${colors.blue};
    background-color: transparent;
    border: none;
    @media (min-width: ${screenSize.smallTablet}) {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.43;
    }
    font-size: 18px;
    font-weight: bold;
    line-height: 2.5;
  `;

  function GenerateAccordian() {
    const title = (
      <>
        <CaretTitleContainer>
          <AccordionTitle>{props.Months[props.CurrentMonth]}</AccordionTitle>
          <Caret
            height={"20"}
            width={"20"}
            css={css`
              transform: ${0 === toggleOpen ? "rotate(-180deg)" : "none"};

              path,
              g {
                stroke: ${colors.orange};
                stroke-width: 1;
              }
              margin: auto 1rem auto 0;
            `}
          >
            &and;
          </Caret>
        </CaretTitleContainer>
      </>
    );
    return (
      <Accordion
        key={0}
        isOpen={0 === toggleOpen}
        setIsOpen={() =>
          0 !== toggleOpen ? setToggleOpen(0) : setToggleOpen(null)
        }
        title={title}
        isAbsolute={true}
        isleftPadded={true}
      >
        <ContentStyle>
          {props.Months.map((month: string, i: number) => {
            return (
              <AccordionLink
                key={i}
                css={css`
                  margin-bottom: 0rem;
                  border-bottom: 1px solid ${colors.blue};
                  &:hover {
                    cursor: pointer;
                    background-color: ${colors.blueGrey};
                  }
                  @media (min-width: ${screenSize.smallTablet}) {
                    border-bottom: none;
                    margin-bottom: 0.2rem;
                  }
                `}
                onClick={() => changeMonth(i)}
              >
                <span
                  css={css`
                    padding: 0 0.7rem;
                  `}
                >
                  {month}
                </span>
              </AccordionLink>
            );
          })}
        </ContentStyle>
      </Accordion>
    );
  }
  return GenerateAccordian();
};

interface MonthData {
  Months: Array<String>;
  CurrentMonth: number;
  setCurrentMonth: any;
}

export default EventsPageAccordion;
