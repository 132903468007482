import * as React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import EventsByMonth from "../components/EventsPage/EventsPageByMonth";
import EventsByCategory from "../components/EventsPage/EventsPageByCategory";
import SEO from "../components/seo";

const EventsOverview = () => {
  const result = useStaticQuery(graphql`
    {
      page: wpPage(title: { eq: "Events" }) {
        content
        title
      }
      events: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Events" } } } }
        }
      ) {
        ...EventNodes
      }
    }
  `);

  let [selection, setSelection] = React.useState("category");
  function toggleViewSelection(): void {
    setSelection(selection === "category" ? "month" : "category");
  }

  const EventToggle = ({ eventPosts }: { eventPosts: CalendarEvent[] }) => {
    if (selection === "category") {
      return (
        <EventsByCategory
          eventPosts={eventPosts}
          toggleViewSelection={toggleViewSelection}
        />
      );
    } else if (selection == "month") {
      return (
        <EventsByMonth
          eventPosts={eventPosts}
          toggleViewSelection={toggleViewSelection}
        />
      );
    }
  };

  const { page, events } = result;
  return (
    <Layout title={page?.title}>
      <SEO title="Events" />
      <EventToggle eventPosts={events?.nodes} />
    </Layout>
  );
};

export default EventsOverview;

export interface CalendarEvent {
  title: string;
  slug: string;
  content: string;
  eventsType: EventDetails;
  featuredImage: {
    node: { localFile: { childImageSharp: { gatsbyImageData: any } } };
  };
  thumbnail: {
    node: { localFile: { childImageSharp: { gatsbyImageData: any } } };
  };
}

interface EventDetails {
  eventAddress: string;
  eventDate: string;
  eventDescription: string;
  eventRegistration?: string;
  eventCost?: string;
  eventSubtitle?: string;
  eventImage?: {
    localFile: { childImageSharp: { gatsbyImageData: any } };
  };
  eventImageSubtitle?: string;
  featuredEvent: Boolean;
  startTime: string;
  type: string;
}
