import { colors, screenSize } from "../../utils/css/themes";
import * as React from "react";
import styled from "@emotion/styled";

const ButtonLabel = styled.div`
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: 0.58px;
  text-align: center;
  color: ${colors.blue};
`;

const Button = styled.button`
  border-radius: 40px;
  border: solid 1px ${colors.blue};
  max-width: 242px;
  max-height: 39px;
  width: 100%;
  text-decoration: none;
  padding: 0.5rem;
  background-color: white;
  margin: 1rem auto;
  @media (min-width: ${screenSize.smallTablet}) {
    margin: 0;
  }
  &:hover {
    div {
      color: white;
    }
    background-color: ${colors.blue};
  }
`;

const ViewToggleButton = (props: ViewToggleButtonProps) => (
  <Button onClick={props.toggleViewSelection}>
    <ButtonLabel>{props.buttonLabel}</ButtonLabel>
  </Button>
);

interface ViewToggleButtonProps {
  buttonLabel: string;
  toggleViewSelection: () => void;
}

export default ViewToggleButton;
