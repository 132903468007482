import styled from "@emotion/styled";
import * as React from "react";
import { buildEventDateTimeString } from "../../utils/buildEventDateTimeString";
import { screenSize } from "../../utils/css/themes";
import FeaturedEventContainer from "./FeaturedEventContainer";
import GenericCard from "../Helpers/GenericCard";
import ViewToggleButton from "./ViewToggleButton";
import { CalendarEvent } from "../../pages/events";

const EventsByCategory = ({
  eventPosts,
  toggleViewSelection,
}: EventsByCategoryProps) => {
  const TopNavigation = styled.div`
    display: flex;
    justify-content: center;
    @media (min-width: ${screenSize.smallTablet}) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
    }
    margin: 1rem auto 0;
    text-align: center;
    flex-direction: column;
  `;

  const EventsCategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem auto 0;
    align-items: space-between;
    @media (min-width: ${screenSize.smallTablet}) {
      align-items: stretch;
    }
  `;

  const EventCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media (min-width: ${screenSize.smallTablet}) {
      display: grid;
      grid-template-rows: repeat(auto-fit);
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 2em;
      grid-column-gap: 2em;
    }
  `;

  function getFeaturedEvents(): CalendarEvent[] {
    return eventPosts?.filter((post: CalendarEvent) => {
      return post.eventsType.featuredEvent;
    });
  }

  /**
   * Filters events for those that occur in the future from today, and returns them in sorted order, closest to farthest out.
   */
  function getFutureEventsSorted(events: CalendarEvent[]) {
    if (!events) return [];

    const today = new Date();
    const dateDistances = events.map((event: CalendarEvent, index: number) => {
      let eventDate = new Date(event.eventsType.eventDate);
      return {
        dateDistance: eventDate.getTime() - today.getTime(),
        index,
      };
    });
    const futureEventIndicesSorted = dateDistances
      .filter((val) => val.dateDistance >= 0)
      .sort((a, b) => a.dateDistance - b.dateDistance);
    const futureEventsSorted = futureEventIndicesSorted.map(
      (eventIndex) => events[eventIndex.index]
    );
    return futureEventsSorted;
  }

  function getSoonestFeaturedEvent() {
    const futureFeaturedEvents = getFutureEventsSorted(getFeaturedEvents());

    if (futureFeaturedEvents.length > 0) {
      return futureFeaturedEvents[0];
    } else {
      return null;
    }
  }

  const MAX_NUM_EVENTS_SHOWN = 9;
  const featuredEvent = getSoonestFeaturedEvent();
  const upcomingEvents = getFutureEventsSorted(eventPosts).slice(
    0,
    MAX_NUM_EVENTS_SHOWN
  );

  return (
    <div>
      <TopNavigation>
        {!!featuredEvent ? <h2>Featured Event</h2> : <h2>Upcoming Events</h2>}
        <ViewToggleButton
          buttonLabel={"View Events by Month"}
          toggleViewSelection={toggleViewSelection}
        />
      </TopNavigation>
      <EventsCategoryContainer>
        {featuredEvent && (
          <>
            <FeaturedEventContainer
              featuredEvent={featuredEvent}
            ></FeaturedEventContainer>
            <h2>Upcoming Events</h2>
          </>
        )}
        {upcomingEvents.length > 0 ? (
          <EventCardContainer>
            {upcomingEvents.map((event: CalendarEvent, i: number) => (
              <GenericCard
                linkURL={"/events/" + event.slug}
                title={event.title}
                description={event.eventsType.eventDescription}
                miniHeader={buildEventDateTimeString(
                  event.eventsType.eventDate,
                  event.eventsType.startTime
                )}
                cardImage={
                  event.eventsType?.eventImage?.localFile.childImageSharp
                    ?.gatsbyImageData
                }
                key={i}
              />
            ))}
          </EventCardContainer>
        ) : (
          <p>
            There are no upcoming events right now... check back soon for
            updates!
          </p>
        )}
      </EventsCategoryContainer>
    </div>
  );
};

interface EventsByCategoryProps {
  eventPosts: CalendarEvent[];
  toggleViewSelection: () => void;
}

export default EventsByCategory;
